import React from "react";
import { useState, useEffect } from "react";
import {
  AutoComplete,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
  message,
} from "antd";
import { CheckCircleFilled, ExclamationCircleFilled } from "@ant-design/icons";
import { authorizationHeader } from "api/util";

import _ from "lodash";
import axios from "axios";
import qs from "qs";

const { Option, OptGroup } = Select;
const { Text } = Typography;
const initKitOptionsUrl = `${process.env.REACT_APP_API_URL}kits`;

export const InputField = ({
  label,
  name,
  rules,
  id,
  placeholder,
  ...props
}) => {
  return (
    <Form.Item label={label} name={name} rules={rules} {...props}>
      <Input id={id} placeholder={placeholder} />
    </Form.Item>
  );
};

export function trackingNumberLink(recordString) {
  const records = recordString?.toString()?.split(",") || [
    recordString?.toString(),
  ];

  return records.map((r) => {
    const trackingNumber = r?.trim() || r;
    const firstChar = trackingNumber
      ? trackingNumber.charAt(0).toUpperCase()
      : null;

    let href = null;

    if (firstChar === "B") {
      // If first letter in the tracking id starts with B, the kit was shipped with mercury
      href = "https://www.shipmercury.com/tracking?TrackingNo=";
    } else if (firstChar === "F" || trackingNumber?.length === 12) {
      // If first letter in the tracking id starts with F or the tracking length is 12, the kit was likely shipped with fedex
      href = "https://www.fedex.com/fedextrack/?trknbr=";
    } else if (trackingNumber?.length <= 22 && trackingNumber?.length >= 20) {
      // If tracking number length is between 20-22 it was likely shipped with USPS
      href =
        "https://tools.usps.com/go/TrackConfirmAction_input?strOrigTrackNum=";
    } else if (trackingNumber?.length === 18) {
      // If tracking number length is 18 it was likely shipped with UPS
      href = "https://www.ups.com/mobile/track?trackingNumber=";
    }

    if (href) {
      return (
        <span>
          <a
            href={`${href}${trackingNumber}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {trackingNumber}
          </a>
          <br />
        </span>
      );
    } else {
      return trackingNumber;
    }
  });
}

export const VersionSelector = ({
  name,
  label,
  versions = [],
  versionNumberPrefix,
  groupByAssay = false,
  required = false,
}) => {
  const assayGroups = groupByAssay
    ? _.groupBy(versions, ({ version_name }) =>
        version_name.slice(0, version_name.indexOf("-"))
      )
    : null;

  /**
   * Pulls version number from version_name fields (mpx-v.4.1.2 => 4.1.2)
   * and sorts list of versions by that version number for that assay
   * @param {{ version_name: string; description: string }} a : n-1 version e.g. { version_name: 'mpx-v4.0' }
   * @param {{ version_name: string; description: string }} b : n version e.g. { version_name: 'mpx-v4.1.2' }
   * @returns {number} Array.prototype.sort return value
   */
  const vNameDesc = (a, b) => {
    a = a.version_name?.split(versionNumberPrefix).pop() ?? "0";
    b = b.version_name?.split(versionNumberPrefix).pop() ?? "0";
    return b
      .replace(/\d+/g, (n) => +n + 100000)
      .localeCompare(a.replace(/\d+/g, (n) => +n + 100000));
  };

  const getOptionList = (versions) =>
    (versions || []).sort(vNameDesc).map((v) => (
      <Option key={v.id} value={v.id}>
        <i>{v.version_name}</i>
        {v.description && <span>{` \u{2192} ${v.description}`}</span>}
      </Option>
    ));

  return (
    <Form.Item name={name} label={label} required={required}>
      <Select allowClear={true} style={{ maxWidth: "300px" }}>
        {groupByAssay ? (
          <>
            {assayGroups.resp && (
              <OptGroup label="Respiratory">
                {getOptionList(assayGroups.resp)}
              </OptGroup>
            )}
            {assayGroups["gtm_resp"] && (
              <OptGroup label="GTM - Respiratory">
                {getOptionList(assayGroups["gtm_resp"])}
              </OptGroup>
            )}
            {assayGroups.covid && (
              <OptGroup label="SARS-CoV-2">
                {getOptionList(assayGroups.covid)}
              </OptGroup>
            )}
            {assayGroups["gtm_covid"] && (
              <OptGroup label="GTM SARS-CoV-2">
                {getOptionList(assayGroups["gtm_covid"])}
              </OptGroup>
            )}
            {assayGroups.hrs && (
              <OptGroup label="HRS">{getOptionList(assayGroups.hrs)}</OptGroup>
            )}
            {assayGroups["gtm_hrs"] && (
              <OptGroup label="GTM HRS">
                {getOptionList(assayGroups["gtm_hrs"])}
              </OptGroup>
            )}
            {assayGroups.noro && (
              <OptGroup label="Norovirus">
                {getOptionList(assayGroups.noro)}
              </OptGroup>
            )}
            {assayGroups["gtm_noro"] && (
              <OptGroup label="GTM Norovirus">
                {getOptionList(assayGroups["gtm_noro"])}
              </OptGroup>
            )}
            {assayGroups.mpx && (
              <OptGroup label="Monkeypox">
                {getOptionList(assayGroups.mpx)}
              </OptGroup>
            )}
            {assayGroups["gtm_mpx"] && (
              <OptGroup label="GTM Monkeypox">
                {getOptionList(assayGroups["gtm_mpx"])}
              </OptGroup>
            )}
          </>
        ) : (
          getOptionList(versions)
        )}
      </Select>
    </Form.Item>
  );
};

export const UploadResultTable = ({ uploadResults }) => {
  // group by batches
  const groupedResults = _.groupBy(uploadResults, (result) =>
    _.get(result, ["value", "batch_name"])
  );
  delete groupedResults["undefined"];
  return (
    <Space direction="vertical" size="large" className="w-100">
      {_.map(groupedResults, (resultsPerBatch, batchName) =>
        batchName ? (
          <div key={batchName}>
            <Typography.Title level={5}>{batchName}</Typography.Title>
            {resultsPerBatch.map(({ value: result }, rIdx) =>
              result ? (
                <Row key={`${batchName}-${rIdx}`} className="pl-1">
                  {result?.plate_name && (
                    <Col span={8}>{result?.plate_name}</Col>
                  )}
                  <Col span={14}>{result?.uploaded_file?.name}</Col>
                  <Col span={2}>
                    {result?.success ? (
                      <CheckCircleFilled style={{ color: "#00c783" }} />
                    ) : (
                      <Tooltip
                        title={`${
                          result?.error || "Upload failed, please try again."
                        }`}
                        color="red"
                      >
                        <ExclamationCircleFilled style={{ color: "red" }} />
                      </Tooltip>
                    )}
                  </Col>
                </Row>
              ) : (
                <></>
              )
            )}
          </div>
        ) : (
          <></>
        )
      )}
      <div key="rework_results">
        <Typography.Title level={5}>
          Created {uploadResults?.reworkResults?.length || 0} Reworks
        </Typography.Title>
      </div>
    </Space>
  );
};

export const KitSelector = ({ onKitSelected, className }) => {
  const [loading, setLoading] = useState(false);
  const [kitLookup, setKitLookup] = useState();
  const [kitOptions, setKitOptions] = useState([]);

  const generateAxiosParams = (kitLookup, cancelToken) => {
    return {
      headers: authorizationHeader(),
      cancelToken: cancelToken,
      params: [
        { kit_id_contains: kitLookup },
        { shipping_kit_id_contains: kitLookup },
      ],
      paramsSerializer: (params) => {
        return qs.stringify({
          _where: {
            _or: params,
          },
        });
      },
    };
  };

  const generateKitOptions = (kits, userInput) => {
    const options = kits
      .filter(({ kit_id, shipping_kit_id }) => kit_id || shipping_kit_id)
      .map(({ id, kit_id, shipping_kit_id, date_received }) => {
        let label = null;

        if (kit_id && shipping_kit_id) {
          label = `${kit_id} - ${shipping_kit_id}`;
        } else if (shipping_kit_id) {
          label = `No Kit ID - ${shipping_kit_id}`;
        } else if (kit_id) {
          label = `${kit_id} - No Therapak ID`;
        }

        return {
          label: label,
          value: id,
          kit_id: kit_id,
          shipping_kit_id: shipping_kit_id,
          date_received: date_received,
        };
      });

    return options;
  };

  const filterKitOptions = (options, userInput) => {
    const uppercaseInput = userInput.toUpperCase();
    const filteredOptions = options
      .filter(({ kit_id, shipping_kit_id }) => {
        return (
          kit_id?.startsWith(uppercaseInput) ||
          shipping_kit_id?.startsWith(shipping_kit_id)
        );
      })
      .sort(({ label }) => label);

    return filteredOptions;
  };

  useEffect(() => {
    if (!kitLookup || kitLookup.length < 2) {
      setKitOptions([]);
    } else {
      const cancelToken = axios.CancelToken;
      const source = cancelToken.source();

      setLoading(true);
      const params = generateAxiosParams(kitLookup, source.token);
      axios
        .get(initKitOptionsUrl, params)
        .then((res) => {
          const options = generateKitOptions(res.data, kitLookup);
          setKitOptions(filterKitOptions(options, kitLookup));
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          if (!axios.isCancel(e)) {
            message.error("Something went wrong!");
            setLoading(false);
          }
        });

      return () => {
        source.cancel();
      };
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kitLookup]);

  const handleSelected = (value, option) => {
    setKitLookup(null);
    onKitSelected(option);
  };

  const handleSearch = (value, event) => {
    const exactMatch = kitOptions.filter((option) => {
      const matchesKitExactly =
        option.kit_id?.toUpperCase() === value?.toUpperCase();
      const matchesShippingExactly =
        option.shipping_kit_id?.toUpperCase() === value?.toUpperCase();
      return matchesKitExactly || matchesShippingExactly;
    })[0];

    if (exactMatch) {
      setKitLookup(null);
      onKitSelected(exactMatch);
    }
  };

  return (
    <div className={className}>
      <Text>Kit IDs*</Text>
      <Row>
        <Col span={8}>
          <AutoComplete
            options={kitOptions}
            style={{ width: "100%" }}
            value={kitLookup}
            onChange={setKitLookup}
            onSelect={handleSelected}
            notFoundContent="No Kit ID or Shipping ID found"
          >
            <Input.Search
              placeholder="Find Kit ID by ID, Shipping ID, or Internal ID"
              loading={loading}
              enterButton
              onSearch={handleSearch}
            />
          </AutoComplete>
        </Col>
      </Row>
    </div>
  );
};

export default KitSelector;

import { Input, Typography } from "antd";
import { usePublicKit } from "api/kit";
import useImperativeQuery from "api/useImperativeQuery";
import { Form } from "components/Wizard";
import { callEach } from "utils";
import { requiredRule } from "utils/rules";
import * as _ from "lodash";
import { Col, Row } from "antd";
import { useState } from "react";

const { Paragraph } = Typography;

const ALREADY_LOGGED = "It looks like this KIT ID has been logged.";
const UNKNOWN_ERROR = (
  <>
    Sorry about this. We’ve run into an issue logging this sample. Are you sure
    you’ve submitted your Kit ID correctly? If so, please contact{" "}
    <a href="mailto:support@biobot.io">support@biobot.io</a> for further
    assistance.
  </>
);

const vesselFactory = (vesselType) => {
  if (vesselType === 2) {
    return {
      prefix: "BOT",
      plural_label: "Bottles",
      singular_label: "Bottle",
      count: 2,
      notification: "Have you checked that the IDs on both your bottles",
      image: (
        <img src={process.env.PUBLIC_URL + "/assets/bottle.svg"} alt="bottle" />
      ),
    };
  } else {
    return {
      prefix: "TUB",
      plural_label: "Tubes",
      singular_label: "Tube",
      count: 3,
      notification: "Have you checked that the IDs on all your tubes",
      image: <img src={process.env.PUBLIC_URL + "/assets/tube.svg"} alt="" />,
    };
  }
};

const KitStep = (props) => {
  const [selectedVessel, setSelectedVessel] = useState(vesselFactory(1));

  const fetchKit = useImperativeQuery(usePublicKit.query);
  const validateKit = async ({ shippingKitId }) => {
    const kit = await fetchKit({ shippingKitId });
    if (kit.hasSample) {
      throw new Form.Error(ALREADY_LOGGED);
    }
  };

  const normalizeValues = (values) => ({
    ...values,
    shippingKitId: `KIT-${values.kit_suffix ?? ""}`,
  });

  const lookupKitType = async (e) => {
    const { value } = e.target;
    try {
      const kit = await fetchKit({
        shippingKitId: `KIT-${value.toUpperCase()}`,
      });

      if (kit) {
        setSelectedVessel(vesselFactory(kit.vessel_type ?? selectedVessel));
      }
    } catch {
      // in the future should probably differentiate between 404's and 401's so that
      // they can be handled separately
    }
  };

  const lookupKitTypeDebounced = _.debounce(lookupKitType, 1000);

  return (
    <Form
      {...props}
      onFinish={callEach(validateKit, props.onFinish)}
      normalizeValues={normalizeValues}
      defaultErrorMessage={UNKNOWN_ERROR}
    >
      <Paragraph>
        {selectedVessel.notification} match the five-character ID following
        "KIT" on the barcode label? Please note: if the IDs don't match, there
        will be a delay in data processing. Refer to the diagram below for
        clarification.
      </Paragraph>
      <div>
        <Row className="max-1960px mt-5 mx-3" gutter={20}>
          <Col xs={24} lg={12}>
            <div>
              <h2>Kit Label</h2>
              <img
                src={process.env.PUBLIC_URL + "/assets/kit_barcode.svg"}
                alt="kit barcode"
              ></img>
            </div>
          </Col>
          <Col xs={24} lg={12}>
            <div>
              <h2 className="mb-5">{selectedVessel.plural_label}</h2>
              {_.times(selectedVessel.count, (i) => (
                <Row className="mb-4" align="left" gutter={6}>
                  <Col className="mr-4">
                    <h3 style={{ paddingTop: "5px" }}>
                      *-
                      <span
                        style={{
                          borderStyle: "solid",
                          borderWidth: "1px",
                          borderRadius: "2px",
                          borderColor: "#00C783",
                        }}
                      >
                        AB123
                      </span>
                    </h3>
                  </Col>
                  <Col>{selectedVessel.image}</Col>
                </Row>
              ))}
            </div>
          </Col>
        </Row>
      </div>
      <Row gutter={24}>
        <Col xs={24} lg={12}>
          <Form.Item
            name="kit_suffix"
            label={newLabel(14, "What kit did you use to take this sample?")}
            rules={[requiredRule("Please enter a kit id")]}
            // Strip the KIT-prefix if the user types it or pastes it
            // Convert lowercase to uppercase--all kit / tube shipping ids should be in caps
            normalize={(value) => value.replace(/^KIT-/, "").toUpperCase()}
          >
            <Input
              style={{ width: "300px" }}
              placeholder="Please enter your Kit ID"
              addonBefore="KIT-"
              onChange={(e) => lookupKitTypeDebounced(e)}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const newLabel = (size, message) => {
  return <span style={{ fontSize: size }}>{message}</span>;
};

export default KitStep;

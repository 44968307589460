import { Col, PageHeader, Row, Typography } from "antd";
import { useState } from "react";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import { usePublicToken } from "api/identity";
import { usePublicKit } from "api/kit";
import { usePublicSamplingLocations } from "api/samplingLocation";
import BrowserWarning from "components/PageWarningBanners/BrowserWarning";
import SampleLogInfoBanner from "components/PageWarningBanners/SampleLogInfoBanner";
import SchedulePickupNoticeBanner from "components/PageWarningBanners/SchedulePickupNoticeBanner";
import Loader from "components/Loader/Loader";
import SubmissionResult from "components/SubmissionResult";
import WithLoading from "components/WithLoading";
import Wizard from "components/Wizard";
import SampleForm from "../Form";
import KitStep from "../components/KitStep";
import LocationStep from "../components/LocationStep";
import OrgConfirmStep from "../components/OrgConfirmStep";
import MaintenanceWarningBanner from "components/PageWarningBanners/MaintenanceWarningBanner";

const { Link, Paragraph, Text } = Typography;

const formProps = {
  layout: "vertical",
  labelComponent: <Text className="text-xl" />,
  wrapperCol: { xs: 20, md: 10 },
};

const SampleLogWizard = ({ setCompletedStatus, ...props }) => {
  const [shippingKitId, setShippingKitId] = useState(null);
  const kitQuery = usePublicKit(
    { shippingKitId },
    { enabled: shippingKitId != null }
  );
  const locationsQuery = usePublicSamplingLocations(
    { organizationId: kitQuery.data?.organization_id },
    { enabled: kitQuery.data?.organization_id != null, placeholderData: [] }
  );

  const kitOrg = {
    id: kitQuery.data?.organization_id,
    name: kitQuery.data?.organization_name,
  };

  const handleKitFinish = async ({ shippingKitId }) => {
    setShippingKitId(shippingKitId);
  };

  return (
    <Wizard singlePage showBackAlways {...props}>
      {({ forms: { organization, sampling_location } }) => (
        <>
          <KitStep name="kit" onFinish={handleKitFinish} {...formProps} />
          <Wizard.WithLoading
            query={kitQuery}
            loadingMessage="Looking up your organization..."
          >
            <OrgConfirmStep
              name="organization"
              organization={kitOrg}
              {...formProps}
            />
          </Wizard.WithLoading>
          <Wizard.WithLoading
            query={locationsQuery}
            isError={false} // make the user enter a new location on error
            loadingMessage="Looking up your organization's sampling locations..."
          >
            <LocationStep
              name="sampling_location"
              locations={organization?.isNewOrg ? null : locationsQuery.data}
              {...formProps}
            />
          </Wizard.WithLoading>
          <SampleForm
            name="sample"
            isPublic={true}
            site={sampling_location?.location}
            siteName={
              sampling_location?.isNewLocation &&
              sampling_location?.sampling_location_name
            }
            kit={kitQuery.data}
            org={kitOrg}
            orgName={organization?.isNewOrg && organization?.organization_name}
            // TODO: do the backend call here instead of in the form itself
            setCompleted={setCompletedStatus}
          />
        </>
      )}
    </Wizard>
  );
};

const SampleLogHelmet = (props) => (
  <Helmet
    defaultTitle="Biobot Analytics Sample Log"
    titleTemplate="Biobot Analytics Sample Log | %s"
    {...props}
  />
);

const SampleLogPublic = () => {
  const [status, setStatus] = useState(null);
  const tokenQuery = usePublicToken({ tokenType: "SAMPLE_LOG" });

  return (
    <WithLoading query={tokenQuery} loadingComponent={Loader}>
      <BrowserWarning />
      {process.env.REACT_APP_CUSTOMER_SAMPLE_LOG_BANNER === "true" && (
        <SampleLogInfoBanner />
      )}
      {process.env.REACT_APP_CUSTOMER_MAINTENANCE_BANNER === "true" && (
        <MaintenanceWarningBanner />
      )}
      <SchedulePickupNoticeBanner />
      <SampleLogHelmet />
      <Row className="max-960px mt-5 mx-2">
        <Col xs={{ offset: 1, span: 22 }} sm={{ offset: 2, span: 20 }}>
          <PageHeader title={<h1>Sample Log</h1>} className="px-0">
            <Paragraph>
              The purpose of this form is for you to submit unique information
              for <Text strong>each</Text> of your samples. If you experience
              any issues submitting your sampling data, please contact us at{" "}
              <Link href="mailto:support@biobot.io">support@biobot.io</Link>.
            </Paragraph>
          </PageHeader>
          {status ? (
            <>
              <SampleLogHelmet>
                {status === 200 ? (
                  <title>Form Submitted Successfully</title>
                ) : (
                  <title>Form Submission Error</title>
                )}
              </SampleLogHelmet>
              <SubmissionResult status={status} />
            </>
          ) : (
            <Col span={24}>
              <SampleLogWizard setCompletedStatus={setStatus} />
            </Col>
          )}
        </Col>
      </Row>
    </WithLoading>
  );
};

export default withRouter(SampleLogPublic);

import { Row, Col, Typography } from "antd";

const { Text } = Typography;

export default function SchedulePickupNoticeBanner() {
  return (
    <Row className="info-banner block text-xl px-4 py-2">
      <Col>
        <Text>
          We've updated our FedEx pickup request link. For both one-time and
          recurring pickups, please use our new online form.
          <br />
          You can access it here:{" "}
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLScksys3VfSU6sy9XX2Amcnd9THauIvy39JfRXJRhFTyktTSDQ/viewform"
            target="_blank"
            rel="noopener noreferrer"
          >
            Biobot Pickup Request Link
          </a>
        </Text>
      </Col>
    </Row>
  );
}
